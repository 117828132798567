<template>
  <span>
    <div v-if="errorType === 'alert-danger'" style="color:#DC3545;">
      <Tooltip direction="top" :contents="tooltipMessage" class="inline">
        <i class="fa fa-exclamation-triangle" style="color:#BC3545;" />
      </Tooltip>
      <span> {{ message }}</span>
    </div>
    <snackbar v-if="errorType === 'snackbar-danger'" :show="true" bsStyle="danger" :delay="delay" :onHideSnackbar="onHide">
      {{ message }}
    </snackbar>
    <div v-if="errorType === 'alert-danger-dismissible'">
      <div class="alert alert-dismissible alert-danger">
        <!-- eslint-disable -->
        <p v-if="html" style="margin-bottom: 0px;" v-html="html" />
        <!-- eslint-enable -->
      </div>
    </div>
  </span>
</template>
<script>
import { Tooltip, Snackbar } from '@cimpress/react-components';
export default {
  components: {
    Tooltip,
    Snackbar
  },
  props: {
    errorType: {
      type: String,
      default: () => null
    },
    message: {
      type: String,
      default: 'Unknown error occurred. please try again.'
    },
    tooltipMessage: {
      type: String,
      default: 'Try again or contact PandaSquad@cimpress.com'
    },
    html: {
      type: String,
      default: null
    },
    delay: {
      type: Number,
      default: 3500
    },
    onHide: {
      type: Function,
      default() { }
    }
  }
};
</script>
<style>
.snackbar-text {
  padding: 10px 10px !important;
  font-size: 15px;
}
.fa-exclamation-triangle {
  font-size: initial !important;
}
</style>
