<template>
  <a style="text-decoration: none" class="list-group-item" @click="selectItem">
    <div class="row">
      <div class="col-sm-10">
        <p class="list-group-item-text row">
          <span class="bold-font col-xs-3" style="overflow: hidden">PDW</span>
          <span class="col-xs-8">{{ cluster.replace('.eu-west-1', '') }}</span>
        </p>
        <p class="list-group-item-text row">
          <span class="bold-font col-xs-3"
                style="overflow: hidden"
          >Database</span>
          <span class="col-xs-9">{{ database }}</span>
        </p>
        <p class="list-group-item-text row">
          <span class="bold-font col-xs-3"
                style="overflow: hidden"
          >Schema</span>
          <span class="col-xs-9">{{ schema }}</span>
        </p>
        <p class="list-group-item-text row">
          <span class="bold-font col-xs-3"
                style="overflow: hidden"
          >Object Name</span>
          <span class="col-xs-9">{{ tableName }}</span>
        </p>
        <p class="list-group-item-text row">
          <span class="bold-font col-xs-3"
                style="overflow: hidden"
          >Object Type</span>
          <span v-if="isView" class="col-xs-9">View</span>
          <span v-else class="col-xs-9">Table</span>
        </p>
        <p v-if="tags" class="list-group-item-text row">
          <span class="bold-font col-xs-3"
                style="overflow: hidden"
          >Tags</span>
          <span v-if="tags.length" class="col-xs-9">{{ tags.join(' | ') }}</span>
          <span v-else class="col-xs-9 text-muted">No tags</span>
        </p>
        <p class="list-group-item-text row">
          <span style="overflow: hidden"
                class="col-xs-3 bold-font"
          >Description</span>
          <span
            v-if="description && !/^\n+$/.test(description)"
            class="col-xs-9 description"
          >{{ removeMarkdown(description) }}</span>
          <!-- Regular expression checks if string only has \n\n\n... -->
          <span
            v-else-if="!description || /^\n+$/.test(description)"
            class="col-xs-9 noDescriptionWarning"
          >No description Added</span>
        </p>
        <hr v-if="columnName" style="margin: 0.3vmin" />
        <p v-if="columnName" class="list-group-item-text row">
          <span class="bold-font col-xs-3"
                style="color: grey"
          >Matching Columns</span>
          <span class="col-xs-9" style="color: grey">{{ columnName }}</span>
        </p>
      </div>
      <div class="col-sm-2">
        <div style="display: flex; flex-wrap: wrap; float: right">
          <div
            v-if="!isPiiLoading && isPiiExisting"
            class="piiDataWarning"
            style="margin-left: 8px"
            @click="(event) => event.preventDefault()"
          >
            <Tooltip
              direction="top"
              :contents="`${piiTooltip}`"
              :class="{ 'scannedPii-badge': isPiiMarkedByScanner }"
            >
              <i class="fa fa-id-card fa-lg" />
            </Tooltip>
          </div>
          <div v-if="isPiiLoading">
            <img
              src="spinner.svg"
              width="20"
              style="margin-top: -5px"
              alt="loading"
            />
          </div>
          <div
            v-if="owner.length < 1"
            class="noOwnerWarning"
            style="margin-left: 8px"
            @click="(event) => event.preventDefault()"
          >
            <Tooltip
              direction="top"
              contents="No stewards have been assigned for this table"
            >
              <i class="fa fa-exclamation-triangle fa-lg" />
            </Tooltip>
          </div>
          <div
            v-if="!description || /^\n+$/.test(description)"
            class="noDescriptionWarning"
            style="margin-left: 8px"
            @click="(event) => event.preventDefault()"
          >
            <Tooltip
              direction="top"
              contents="No description available for this table"
            >
              <i class="fa fa-exclamation-triangle fa-lg" />
            </Tooltip>
          </div>
          <Bookmark
            :keyValue="keyValue"
            class="fa-lg"
            style="margin-left: 8px"
          />
        </div>
      </div>
    </div>
  </a>
</template>

<script>
import { mapActions } from 'vuex';
import Bookmark from './Bookmark';
import removeMd from 'remove-markdown';
import { Tooltip } from '@cimpress/react-components';

export default {
  name: 'SearchResultItems',
  inject: ['platformClient'],
  components: {
    Bookmark,
    Tooltip
  },
  props: {
    cluster: {
      type: String,
      required: true,
      default: ''
    },
    database: {
      type: String,
      required: true,
      default: ''
    },
    schema: {
      type: String,
      required: true,
      default: ''
    },
    tableName: {
      type: String,
      required: true,
      default: ''
    },
    tags: {
      type: Array,
      default: null
    },
    description: {
      type: String,
      default: ''
    },
    keyValue: {
      type: String,
      default: ''
    },
    columnName: {
      type: String,
      default: ''
    },
    owner: {
      type: Array,
      default: () => []
    },
    isView: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      piiTooltip: '',
      piiData: false,
      isPiiLoading: true
    };
  },
  computed: {
    isPiiExisting() {
      let isPiiExistingForColumn = false;
      if (this.piiData && this.piiData.piiFields) {
        for (let piiField in this.piiData.piiFields) {
          isPiiExistingForColumn
            = (this.piiData.piiFields[piiField].detectedPiiTypes
              && Object.keys(this.piiData.piiFields[piiField].detectedPiiTypes)
                .length > 0
              && !this.piiData.piiFields[piiField].piiCurated)
            || this.piiData.piiFields[piiField].piiTagged === true;
          if (isPiiExistingForColumn) {
            break;
          }
        }
      }
      return isPiiExistingForColumn;
    },
    isPiiMarkedByScanner() {
      for (let column in this.piiData.piiFields) {
        // Check if column contains PII
        if (
          (this.piiData.piiFields[column].detectedPiiTypes
            && Object.keys(this.piiData.piiFields[column].detectedPiiTypes)
              .length > 0)
          || this.piiData.piiFields[column].piiTagged === true
        ) {
          if (
            !(
              this.piiData.piiFields[column].detectedPiiTypes
              && Object.keys(this.piiData.piiFields[column].detectedPiiTypes)
                .length > 0
              && !this.piiData.piiFields[column].piiTagged
              && !this.piiData.piiFields[column].jsonFields
            )
          ) {
            return false;
          }
        }
      }
      return true;
    }
  },
  async created() {
    await this.getPiiData();
    this.getPiiBadgeTooltip();
  },
  methods: {
    ...mapActions({
      setSelectedTreeItem: 'search/setSelectedTreeItem'
    }),
    selectItem() {
      const queryPath = `${this.cluster}.${this.database}.${this.schema}.${this.tableName}`;
      if (this.$route.path !== '/') {
        this.$router.push({ path: '/', query: { selectedTreeItem: queryPath } });
      }
      this.setSelectedTreeItem({
        selectedTreeItem: queryPath
      });
    },
    removeMarkdown(description) {
      let text = removeMd(description);
      if (text.length > 200) {
        let trimDescription = text.substring(0, 200);
        return `${trimDescription.substr(
          0,
          Math.min(trimDescription.length, trimDescription.lastIndexOf(' '))
        )}...`;
      }
      return text;
    },
    async getPiiData() {
      this.isPiiLoading = true;
      try {
        let account = this.cluster.replace('.eu-west-1', '');
        let piiResponse = await this.platformClient.get(
          `https://pii.pdw.cimpress.io/v0/accounts/${account}/databases/${this.database.toUpperCase()}/schemas/${this.schema.toUpperCase()}/objects/${this.tableName.toUpperCase()}`
        );
        this.piiData = piiResponse.data;
      } catch (e) {
        if (e.status !== 404) {
          console.log(e);
        }
      }
      this.isPiiLoading = false;
    },
    getPiiBadgeTooltip() {
      if (this.piiData) {
        this.piiTooltip = this.isPiiMarkedByScanner
          ? 'Our scanners detected possible PII data on this table'
          : 'Table contains PII';
      }
    }
  }
};
</script>
<style scoped>
.list-group-item:hover::after {
  content: '';
  position: absolute;
  left: 0%;
  right: 0%;
  height: 100%;
  transform: translate(0px, -88%);
  width: 0px;
  left: 0;
  border: 1px solid #0088a9;
}
.description {
  color: #777;
}
.noDescriptionWarning {
  color: #ffae42;
}
.noOwnerWarning {
  color: #cc3300;
}
.scannedPii-badge {
  color: #ee96cc;
}
</style>
