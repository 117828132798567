<template>
  <div>
    <div class="col-md-1" />
    <div class="mainPages col-md-10">
      <BookmarksList />
    </div>
  </div>
</template>

<script>
import BookmarksList from '@/components/MyBookmarks/BookmarksList';

export default {
  name: 'MyBookmarks',
  components: {
    BookmarksList
  }
};
</script>
