<template>
  <div v-if="userManager.userData.app_metadata.canonical_id">
    <div v-if="loading" align="center">
      <img src="spinner.svg" :width="loadingWidth" alt="loading" />
    </div>
    <Tooltip v-if="!loading" direction="top" contents="Bookmark">
      <div @click="toggleBookmark">
        <i
          :class="`fa ${
            (isBookmarked && !hovered) || (!isBookmarked && hovered)
              ? 'fa-bookmark'
              : 'fa-bookmark-o'
          }`"
          @mouseover="hovered = true"
          @mouseleave="hovered = false"
        />
      </div>
    </Tooltip>
    <error-popup
      v-if="errorMessage"
      errorType="snackbar-danger"
      :message="errorMessage"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { Tooltip } from '@cimpress/react-components';
import ErrorPopup from './ErrorPopup.vue';

export default {
  name: 'Bookmark',
  inject: ['userManager', 'metadataPlatformClient'],
  components: {
    Tooltip,
    ErrorPopup
  },
  props: {
    keyValue: {
      type: String,
      required: true
    },
    loadingWidth: {
      type: Number,
      default: 13.4
    }
  },
  data() {
    return {
      hovered: false,
      loading: false,
      errorMessage: false,
      isBookmarked: false
    };
  },
  computed: {
    ...mapState({
      bookmarks: state => state.bookmark.bookmarks.table
    })
  },
  async created() {
    if (!this.bookmarks) {
      try {
        await this.getAllBookmarks({ platformClient: this.metadataPlatformClient, userEmail: this.userManager.userData.app_metadata.canonical_id });
      } catch (error) {
        console.log(error);
      }
    }
    this.setBookmark();
  },
  methods: {
    ...mapActions({
      getAllBookmarks: 'bookmark/getAllBookmarks',
      updateBookmarks: 'bookmark/updateBookmarks',
      removeBookmark: 'bookmark/removeBookmark'
    }),
    async toggleBookmark(e) {
      e.preventDefault();
      e.stopPropagation();

      this.errorMessage = false;
      this.loading = true;
      this.hovered = false;
      try {
        if (!this.isBookmarked) {
          await this.updateBookmarks({
            platformClient: this.metadataPlatformClient,
            userEmail: this.userManager.userData.app_metadata.canonical_id,
            type: 'table',
            keyValue: this.keyValue
          });
        } else {
          await this.removeBookmark({
            platformClient: this.metadataPlatformClient,
            userEmail: this.userManager.userData.app_metadata.canonical_id,
            type: 'table',
            keyValue: this.keyValue
          });
        }
      } catch (error) {
        this.errorMessage
          = error.message
          || error.data.message
          || error.data.title
          || (error.status && `Error occurred with status code ${error.status}`)
          || 'Unknown error occurred.';
        console.log(error);
      }
      this.loading = false;
      this.hovered = false;
      this.isBookmarked = !this.isBookmarked;
    },
    setBookmark() {
      const bookmark = (this.bookmarks || []).find(b => this.keyValue === encodeURIComponent(`${b.database}://${b.cluster}.${b.schema}/${b.name}`));
      this.isBookmarked = bookmark !== undefined;
    }
  }
};
</script>
