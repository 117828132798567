<template>
  <div>
    <div v-if="userManager.userData.app_metadata.canonical_id && !loading" class="list-group">
      <searchResultItems
        v-for="bookmarkResult in bookmarkResults"
        :key="bookmarkResult.id"
        :cluster="bookmarkResult.cluster"
        :database="bookmarkResult.database"
        :schema="bookmarkResult.schema"
        :tableName="bookmarkResult.name"
        :description="bookmarkResult.description"
        :keyValue="getKey(bookmarkResult)"
        :owner="[`to-be-implemented`]"
        :isView="bookmarkResult.is_view"
      />
      <div v-if="!bookmarkResults.length && !errorMessage" class="alert alert-warning">
        <b>Warning!</b>
        <p>
          You have not bookmarked a table yet.
          Please contact Data Platform Panda team at
          <a
            href="mailto:PandaSquad@cimpress.com"
          >PandaSquad@cimpress.com</a> for assistance.
        </p>
      </div>
    </div>
    <div v-if="loading" align="center">
      <img src="spinner.svg" alt="loading">
    </div>
    <div v-if="!userManager.userData.app_metadata.canonical_id && !loading && !errorMessage" class="alert alert-warning">
      <p><b>Warning!</b></p>
      <p>
        You're not yet added to the data discovery platform. Please contact Data Platform at
        <a href="mailto:PandaSquad@cimpress.com">PandaSquad@cimpress.com</a> for assistance.
      </p>
    </div>
    <error-popup v-if="errorMessage"
                 errorType="alert-danger-dismissible"
                 :html="`${errorMessage}. Try refreshing or contact Data Platform Panda team at <a href='mailto:PandaSquad@cimpress.com'> PandaSquad@cimpress.com</a> for assistance.`"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ErrorPopup from '../ErrorPopup.vue';
import searchResultItems from '../searchResultItems';

export default {
  name: 'ListGroup',
  inject: ['userManager', 'metadataPlatformClient'],
  components: {
    searchResultItems,
    ErrorPopup
  },
  data() {
    return {
      bookmarkResults: false,
      loading: true,
      errorMessage: null
    };
  },
  computed: {
    ...mapState({
      bookmarks: state => state.bookmark.bookmarks
    }),
    getKey() {
      return b => encodeURIComponent(`${b.database}://${b.cluster}.${b.schema}/${b.name}`);
    }
  },
  async created() {
    this.loading = true;
    this.errorMessage = null;
    try {
      await this.getAllBookmarks({ platformClient: this.metadataPlatformClient, userEmail: this.userManager.userData.app_metadata.canonical_id });
      this.bookmarkResults = this.bookmarks.table;
    } catch (e) {
      this.errorMessage = e.message || e.data.message || e.data.title || (e.status && `Error occurred with status code ${e.status}`) || 'Unexpected error occurred.';
      console.log(e);
    }
    this.loading = false;
  },
  methods: {
    ...mapActions({
      getAllBookmarks: 'bookmark/getAllBookmarks'
    })
  }
};
</script>
